import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import Container from '../../components/container'
import PageTitle from '../../components/page-title'
import InfoSubNavigation from '../../components/info-sub-navigation'
import MediaContent from '../../components/media-content'
import MetaTitle from '../../components/meta-title'

// TODO: Use graphQl to retrieve the image
import image1 from '../../assets/images/info/photoshoot-1.jpg'
import image2 from '../../assets/images/info/photoshoot-2.jpg'
import termsAndConditions from '../../assets/algemene-voorwaarden.pdf'
import MetaDescription from '../../components/meta-description'

export default function PhotoshootPage () {
  return (
    <Layout>
      <MetaTitle>Fotoshoot Werkwijze</MetaTitle>
      <MetaDescription>
        Hoe gaat een fotoshoot van je dier in zijn werk? Hier vind je alle info
        die je nodig hebt, zodat je je goed kan voorbereiden.
      </MetaDescription>

      <div className='container-wide pt-40'>
        <PageTitle className='mb-40'>Info</PageTitle>
        <InfoSubNavigation />
      </div>

      <Container className='pb-40'>
        <MediaContent className='mb-56' imageUrl={image1}>
          <p className='mb-32'>
            Kriebelt het na het bekijken van{' '}
            <Link to='/portfolio/paarden' className='link'>
              mijn portfolio
            </Link>
            ? Dan spreken we toch gewoon eens af!
          </p>

          <h1 className='font-semibold text-14 font-sans mb-16'>
            Een fotoreportage gaat als volgt te werk:
          </h1>

          <ol className='list-decimal list-decimal pl-16 mb-32'>
            <li className='pl-16'>
              Je stuurt me een bericht via{' '}
              <Link to='/contact' className='link'>
                contact
              </Link>{' '}
              of via mijn{' '}
              <a
                href='https://www.facebook.com/fotografielaurenceverheijen/'
                className='link'
                target='_blank'
                rel='noopener noreferrer'
              >
                Facebook pagina
              </a>
              .
            </li>
            <li className='pl-16'>
              Vervolgens zoeken we een geschikte datum, tijdstip en locatie. Dit
              kan bij je thuis zijn, maar bijvoorbeeld ook in een natuurdomein.
              De locatie is niet beperkt tot provincie Antwerpen, ik fotografeer
              in heel Vlaanderen (en eventueel zelfs Nederland).
            </li>
            <li className='pl-16'>
              Op de dag van de shoot kom ik op het afgesproken uur naar de
              locatie. Indien het weer toch te slecht is op de afgesproken
              datum, wordt de shoot kosteloos verplaatst. Gemiddeld duurt een
              shoot 1 à 1.5u.
            </li>
            <li className='pl-16'>
              Na afloop van de shoot ontvang je een link naar een online gallerij waar je je favoriete foto's kan uitkiezen.
            </li>
            <li className='pl-16'>
              Ik bewerk de uitgekozen foto's waarna je ze ontvangt op groot formaat via een digitale download link.
            </li>
          </ol>

          <p>
            Bij het boeken van een shoot ga je akkoord met de{' '}
            <a href={termsAndConditions} className='link'>
              algemene&nbsp;voorwaarden
            </a>
            .
          </p>
        </MediaContent>

        <h2 className='text-16 font-semibold uppercase mt-0 mb-24'>FAQ</h2>

        <MediaContent className='' imageUrl={image2} isTopAligned>
          <dl>
            <dt className='font-semibold text-14 font-sans mb-16'>
              Hoe bereid ik me voor op zo'n fotoreportage?
            </dt>
            <dd className='mb-24'>
              <ul className='dashed-list'>
                <li>Zorg dat het model proper is.</li>
                <li>
                  Let ook op je eigen kledij, maak er een mooi geheel van.
                </li>
                <li>Leuke accessoires mag altijd!</li>
                <li>
                  Een extra helper is (bij dierenreportages) erg handig om de
                  aandacht te trekken.
                </li>
                <li>
                  Andere hulpmiddelen zijn; een speeltje, wat koekjes, een
                  balletje,...
                </li>
                <li>
                  Denk op voorhand na over verschillende poses en oefen hierop
                  met je dier (stilstaan, zit, ...)
                </li>
              </ul>
            </dd>

            <dt className='font-semibold text-14 font-sans mb-16'>
              Hoe kies ik een geschikte locatie?
            </dt>

            <dd className='mb-24'>
              <ul className='dashed-list'>
                <li>
                  Houd rekening met het karakter van je dier. Is hij heel
                  zenuwachtig op vreemde locaties? Dan kies je beter voor een
                  vertrouwd domein.
                </li>
                <li>
                  Wat vind je zelf leuk? Droom je bijvoorbeeld van spetterende
                  waterfoto's? Kies dan een locatie uit waar je in het water
                  kan!
                </li>
                <li>
                  Ga na of huisdieren/paarden toegestaan zijn op de locatie die
                  je koos.
                </li>
                <li>
                  Als je echt geen idee hebt, geen paniek. We kunnen samen
                  zoeken naar de meest geschikte plek.
                </li>
              </ul>
            </dd>

            <dt className='font-semibold text-14 font-sans mb-16'>
              Wanneer krijg ik de foto's?
            </dt>
            <dd>
              Op de dag van de shoot krijg je een voorproefje van 2 foto’s, de
              rest van de foto’s volgt binnen de 14 werkdagen.
            </dd>
          </dl>
        </MediaContent>
      </Container>
    </Layout>
  )
}
